body {
  overflow: hidden;
  margin: 0px;
}

.footer {
  color: #717171;
  border-top: 1px solid #bcbcbc;
}

.app-bar {
  background-color: #0846a8;
  width: 100%;
  height: 60px;
}

.app-bar img {
  width: 120px;
}
